import queryString from 'query-string';
import React, { useEffect } from 'react';
import { DetailedServicesFirst } from './DetailedServicesFirst/DetailedServicesFirst';
import { DetailedServicesSecond } from './DetailedServicesSecond/DetailedServicesSecond';
import { RocketPage } from './RocketPage/RocketPage';

export const DetailedServices = () => {

    const getPageFromUrl = () => {
        const { search } = window.location;
        const section = queryString.parse(search).section;
        return Array.isArray(section) ? section[0] : section;
    }

    useEffect(() => {
        const currentSection = getPageFromUrl()

        if (currentSection) {
            const el = document.getElementById(currentSection);
            window.scrollTo(0, 0);

            if (el) {
                const elTopCoord = el.getBoundingClientRect().top;
                if (elTopCoord > 20) {
                    setTimeout(() => {
                        window.scrollTo({
                            top: elTopCoord,
                            behavior: 'smooth',
                        });
                    }, 100);
                }
            }
        }
    }, [])

    return (
        <>
            <DetailedServicesFirst />
            <DetailedServicesSecond />
            <RocketPage />
        </>
    );
}