import classNames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useState } from 'react';
import minus from '../../../../../images/servicesPage/minus.svg';
import plus from '../../../../../images/servicesPage/plus.svg';
import * as styles from './DetailedExpertiseItem.module.scss';

interface DetailedExpertiseItemProps {
    isMobileModActive: boolean
    name: string
    img: string
    violetCall?: boolean
    text: string
}

export const DetailedExpertiseItem: React.FC<DetailedExpertiseItemProps> = ({ isMobileModActive, name, img, violetCall, text }) => {
    const [isOpened, setIsOpened] = useState(false)
    const breakpoints = useBreakpoint()

    const toggleOpen = () => {
        setIsOpened((prevIsOpened) => !prevIsOpened);
    };

    const getTopLine = () => {
        const miniImgClass = classNames(styles.miniImg, {
            [styles.violetImg]: violetCall,
        });

        return (
            <div className={styles.top}>
                <div className={styles.imgWrapper}>
                    <img className={miniImgClass} src={img} alt="img" />
                </div>

                <div className={styles.nameWrapper}>
                    <span className={styles.right}>{name}</span>
                    {isMobileModActive && (
                        <div className={styles.action} onClick={toggleOpen}>
                            {isOpened ? <img src={minus} alt={'-'} /> : <img src={plus} alt={'+'} />}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const getBottomLine = () => {
        const descriptionWrapperClass = classNames(styles.descriptionWrapper, {
            [styles.violetDescription]: violetCall,
        });

        return (
            <div className={styles.bottom}>
                <div className={descriptionWrapperClass} dangerouslySetInnerHTML={{ __html: text }}></div>
            </div>
        );
    }

    const itemWrapperClass = classNames(styles.itemWrapper, {
        [styles.violetItemWrapper]: violetCall,
    });

    if (breakpoints.l) {
        return (
            <div className={itemWrapperClass}>
                {getTopLine()}
                {isOpened && getBottomLine()}
            </div>
        );
    }

    return (
        <div className={itemWrapperClass}>
            {getTopLine()}
            {getBottomLine()}
        </div>
    );
}