// extracted by mini-css-extract-plugin
export var container = "DetailedServicesFirst-module--container--c0b23";
export var contentBlock = "DetailedServicesFirst-module--contentBlock--e574d";
export var gears = "DetailedServicesFirst-module--gears--944e1";
export var headers = "DetailedServicesFirst-module--headers--d6b79";
export var imgsLine = "DetailedServicesFirst-module--imgsLine--426b8";
export var puzzle = "DetailedServicesFirst-module--puzzle--a9426";
export var puzzleBlock = "DetailedServicesFirst-module--puzzleBlock--795cc";
export var puzzleText = "DetailedServicesFirst-module--puzzleText--3e546";
export var puzzleTextFirstLine = "DetailedServicesFirst-module--puzzleTextFirstLine--edadd";
export var puzzleTextTitle = "DetailedServicesFirst-module--puzzleTextTitle--18b68";
export var servicesext = "DetailedServicesFirst-module--servicesext--b069d";
export var title = "DetailedServicesFirst-module--title--d193a";
export var titleDescription = "DetailedServicesFirst-module--titleDescription--a47f0";
export var wrapper = "DetailedServicesFirst-module--wrapper--ae13d";