import { useBreakpoint } from "gatsby-plugin-breakpoints"
import React from "react"
import bracket from "../../../../images/servicesPage/bracket.svg"
import magnifier from "../../../../images/servicesPage/magnifier.svg"
import ok from "../../../../images/servicesPage/ok.svg"
import pencil from "../../../../images/servicesPage/pencil.svg"
import wrench from "../../../../images/servicesPage/wrench.svg"
import Button from "../../../Button"
import { Point } from "./Point"
import * as styles from "./RocketPage.module.scss"

const ROCKET_POINTS = [
  {
    svg: magnifier,
    title: "Plan and analyse",
    text: "Get the aims, solve the pains",
  },
  {
    svg: pencil,
    title: "Design, architect and prototype",
    text: "Starting with a low-level and improving up to the final layout",
  },
  {
    svg: bracket,
    title: "Implement",
    text: "Choosing reliable technnologies and platforms that will allow to easily add new features and scale",
  },
  {
    svg: ok,
    title: "Test",
    text: "Inrtoducing CI/CD from the very beginning to make the whole process clear and easy to control ",
  },
  {
    svg: wrench,
    title: "Maintain",
    text: "Deploying to the platform of your choice and supporting the whole product after release",
  },
]

export const RocketPage: React.FC = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className={styles.wrapper} id="startup-mode">
      <div className={styles.top}>
        <span className={styles.topTitle}>3. Startup Mode</span>
        <div className={styles.topText}>
          There is a long way from scratch idea to prototype, implementation,
          launch and support — and you need experts in emerging technologies by
          your side through this journey.
          <br />
          <br />
          Solving all the problems any startup faces at the very beginning —
          that's what makes us exceptional and allows you to build a really
          strong and devoted team.
        </div>
      </div>

      <div className={styles.bottom}>
        {!breakpoints.l && <div className={styles.left}></div>}
        <div className={styles.right}>
          {ROCKET_POINTS.map((el, i) => {
            return (
              <Point
                key={"servicePoint" + i}
                svg={el.svg}
                title={el.title}
                text={el.text}
                withoutDottedLine={i === ROCKET_POINTS.length - 1}
                increasedHeight={i > ROCKET_POINTS.length - 4}
              />
            )
          })}
          <Button
            className={styles.button}
            text={"Contact Us"}
            color={"yellow"}
            onClick={() => (window.location.href = "mailto:info@v4scale.com")}
          />
        </div>
      </div>
      {breakpoints.l ? (
        <div className={styles.rocketMobileWrapper}>
          <div className={styles.rocketMobile}></div>
        </div>
      ) : (
        <div className={styles.rocketDesktop}></div>
      )}
    </div>
  )
}
