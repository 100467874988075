import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO"
import { DetailedServices } from "../components/Services/DetailedServices/DetailedServices"

const Services: React.FC = () => (
  <Layout>
    <div>
      <SEO
        title="V4Scale services"
        description="V4Scale is an Israeli company that expands the local R&D capabilities of leading Israeli and US technology companies with their diverse and skilled remote workforce from all over the world."
      />
      <DetailedServices />
    </div>
  </Layout>
)
export default Services
