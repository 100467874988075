import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import ci from '../../../../images/servicesPage/ci.svg';
import database from '../../../../images/servicesPage/database.svg';
import microservices from '../../../../images/servicesPage/microservices.svg';
import send from '../../../../images/servicesPage/send.svg';
import ux from '../../../../images/servicesPage/ux.svg';
import violet_idea from '../../../../images/servicesPage/violet_idea.svg';
import violet_location from '../../../../images/servicesPage/violet_location.svg';
import violet_principles from '../../../../images/servicesPage/violet_principles.svg';
import { DetailedExpertiseItem } from './DetailedExpertiseItem/DetailedExpertiseItem';
import * as styles from './DetailedServicesSecond.module.scss';

const MAS_OF_EXPERTISES = [
    {
        img: database,
        name: 'Rehost',
        desc: 'Redeploy an application component to another physical, virtual or cloud infrastructure without recompiling, altering the application code, or modifying features and functions.',
    },
    {
        img: microservices,
        name: 'Rearchitect',
        desc: 'Significantly alter the application code so you can move to the new architecture and fully exploit best capabilities of the new platform.',
    },
    {
        img: ci,
        name: 'Replatform',
        desc: 'Migrate an application component to a new runtime platform. Make minimal code changes to adapt to the new platform, but don’t change the code structure or features it provides.',
    },
    {
        img: ux,
        name: 'UX/UI Redesign',
        desc: 'From understanding existing users and business goals, through competitive analysis, redesign, and user testing.',
    },
];

const VIOLET_MAS = [
    {
        img: violet_idea,
        name: 'Our team members',
        desc: 'Our team at V4Scale is comprised of exceptional talent sourced globally.</br></br>They are graduates with honors from renowned universities around the world. Many of our team members are winners of international programming, math competitions, and hackathons.</br></br>Moreover, we are proud to have alums from leading IT companies such as Dell-EMC, Yandex, Nvidia, Netcracker, JetBrains, and other prominent names.',
    },
    {
        img: violet_principles,
        name: 'Our principles',
        desc: 'Discipline </br></br> High motivation </br></br> Responsibility </br></br> Availability 24/7',
    },
    {
        img: violet_location,
        name: 'Our location',
        desc: 'At V4Scale, we are headquartered in Tel Aviv with a branch in Tbilisi. However, our globally distributed team truly sets us apart, allowing our exceptional talent to work from anywhere in the world. By embracing remote work, we provide our staff with the freedom and flexibility to excel in their roles while attracting the best professionals from around the globe.',
    },
];

export const DetailedServicesSecond = () => {
    const breakpoints = useBreakpoint()

    const getVioletLine = () => {
        return (
            <div className={styles.top}>
                {VIOLET_MAS.map((el, i) => {
                    return (
                        <div className={styles.topItem} key={'violetImg' + i}>
                            <DetailedExpertiseItem
                                isMobileModActive={breakpoints.l}
                                violetCall
                                img={el.img}
                                name={el.name}
                                text={el.desc}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    const getColumns = (minIndex?: number, maxIndex?: number) => {
        return MAS_OF_EXPERTISES.slice(minIndex, maxIndex).map(item => {
            return (
                <DetailedExpertiseItem
                    isMobileModActive={breakpoints.l}
                    key={item.name}
                    img={item.img}
                    name={item.name}
                    text={item.desc}
                />
            );
        }
        )
    }

    return (
        <div className={styles.wrapper}>
            {getVioletLine()}

            <div className={styles.bottom} id="legacy-system-modernization">
                <div className={styles.bottomTop}>
                    <div className={styles.legacyTextBlock}>
                        <div className={styles.legacyTitle}>
                            2. Legacy System Modernization
                        </div>

                        <div className={styles.legacyText}>
                            When it comes up to legacy systems modernization, many factors should be taken into
                            account — existing platform and codebase, customer's requirements, business goals and
                            delivery plans. No need to worry — everything is considered.
                        </div>

                        <div className={styles.legacyText}>
                            We do it by delivering a <strong>new digital experience, unifying omnichannel services, managing
                                inventory</strong> in real-time, discovering <strong>actionable insights, </strong>
                            and <strong>scaling infrastructure</strong> on-demand.
                        </div>
                    </div>
                    <div className={styles.sendIcon}>
                        <img className={styles.sendImage} src={send} alt="V4Scale logo" />
                    </div>
                </div>
                <div className={styles.bottomBot}>
                    {getColumns()}
                </div>
            </div>
        </div>
    );
}
