import classNames from 'classnames';
import React from 'react';
import * as styles from './RocketPage.module.scss';

interface PointProps {
    svg: string
    title: string
    text: string
    withoutDottedLine: boolean
    increasedHeight: boolean
}

export const Point: React.FC<PointProps> = ({ svg, title, text, withoutDottedLine, increasedHeight }) => {
    const lineClass = classNames(styles.line, {
        [styles.increasedHeightLine]: increasedHeight,
    });
    return (
        <div className={lineClass}>
            <div className={styles.pointLeft}>
                <img className={styles.svgClass} src={svg} alt="Icon" />
                {!withoutDottedLine && <div className={styles.dottedLine}></div>}
            </div>

            <div className={styles.pointRight}>
                <span className={styles.pointTitle}>{title}</span>
                <span className={styles.pointText}>{text}</span>
            </div>
        </div>
    );
}