// extracted by mini-css-extract-plugin
export var action = "DetailedExpertiseItem-module--action--3ecb2";
export var bottom = "DetailedExpertiseItem-module--bottom--685fa";
export var descriptionWrapper = "DetailedExpertiseItem-module--descriptionWrapper--aa457";
export var imgWrapper = "DetailedExpertiseItem-module--imgWrapper--2a357";
export var itemWrapper = "DetailedExpertiseItem-module--itemWrapper--88786";
export var miniImg = "DetailedExpertiseItem-module--miniImg--cc584";
export var nameWrapper = "DetailedExpertiseItem-module--nameWrapper--30f44";
export var top = "DetailedExpertiseItem-module--top--090ed";
export var violetDescription = "DetailedExpertiseItem-module--violetDescription--d9d4b";
export var violetImg = "DetailedExpertiseItem-module--violetImg--7d556";
export var violetItemWrapper = "DetailedExpertiseItem-module--violetItemWrapper--0459b";