// extracted by mini-css-extract-plugin
export var bottom = "DetailedServicesSecond-module--bottom--e2d64";
export var bottomBot = "DetailedServicesSecond-module--bottomBot--c126e";
export var bottomTop = "DetailedServicesSecond-module--bottomTop--32c4d";
export var itemColumn = "DetailedServicesSecond-module--itemColumn--2c4b5";
export var legacyText = "DetailedServicesSecond-module--legacyText--e7fba";
export var legacyTextBlock = "DetailedServicesSecond-module--legacyTextBlock--6d6f0";
export var legacyTitle = "DetailedServicesSecond-module--legacyTitle--4d95f";
export var sendIcon = "DetailedServicesSecond-module--sendIcon--3c8ae";
export var sendImage = "DetailedServicesSecond-module--sendImage--a7481";
export var top = "DetailedServicesSecond-module--top--22877";
export var topItem = "DetailedServicesSecond-module--topItem--7019e";
export var wrapper = "DetailedServicesSecond-module--wrapper--c3b97";