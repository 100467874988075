// extracted by mini-css-extract-plugin
export var bottom = "RocketPage-module--bottom--1dd50";
export var button = "RocketPage-module--button--6d392";
export var dottedLine = "RocketPage-module--dottedLine--b3aae";
export var fakeLink = "RocketPage-module--fakeLink--b4d45";
export var increasedHeightLine = "RocketPage-module--increasedHeightLine--fdf78";
export var left = "RocketPage-module--left--5da63";
export var line = "RocketPage-module--line--a5ee3";
export var orangeColorProp = "RocketPage-module--orangeColorProp--b5c95";
export var pointLeft = "RocketPage-module--pointLeft--60af9";
export var pointRight = "RocketPage-module--pointRight--39b90";
export var pointText = "RocketPage-module--pointText--80a9f";
export var pointTitle = "RocketPage-module--pointTitle--34ee1";
export var right = "RocketPage-module--right--3e37d";
export var rocketDesktop = "RocketPage-module--rocketDesktop--a3932";
export var rocketMobile = "RocketPage-module--rocketMobile--5b1e8";
export var rocketMobileWrapper = "RocketPage-module--rocketMobileWrapper--06630";
export var svgClass = "RocketPage-module--svgClass--a883d";
export var top = "RocketPage-module--top--7cdde";
export var topText = "RocketPage-module--topText--6c584";
export var topTitle = "RocketPage-module--topTitle--ece0b";
export var wrapper = "RocketPage-module--wrapper--d5c34";