import React from 'react';
import gears from '../../../../images/servicesPage/gears.svg';
import puzzle from '../../../../images/servicesPage/puzzle.svg';
import BackButton from '../../../BackButton/BackButton';
import SectionHeader from '../../../SectionHeader';
import * as styles from './DetailedServicesFirst.module.scss';

export const DetailedServicesFirst: React.FC = () => {

    return (
        <div className={styles.wrapper} id="remote-development-team">
            <div className={styles.headers}>
                <SectionHeader main="Our Services" secondary="Three ways to work" />
                <img className={styles.gears} src={gears} alt="Services" />
                <BackButton />
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.title}>
                    <span>1. Remote Development Team</span>
                </div>

                <div className={styles.titleDescription}>
                    <div>Solving your company’s engineering resource problem we reduce</div>
                    <div>the operational cost and optimize it for a long run.</div>
                    <br />
                    <div>Starting with hiring top-tier technical experts, we continue with</div>
                    <div>establishment and management of your very own development</div>
                    <div>center — everything for desired timeframes and results.</div>
                </div>

                <div className={styles.imgsLine}>
                    <div className={styles.puzzleBlock}>
                        <img className={styles.puzzle} src={puzzle} alt="V4Scale logo" />
                    </div>

                    <div className={styles.puzzleText}>
                        <span className={styles.puzzleTextTitle}>How we do that</span>
                        <div className={styles.puzzleTextFirstLine}>We hire, train, raise, manage, and retain</div>
                        <div>open-minded product and platform</div>
                        <div>development specialists whose careers are</div>
                        <div> dedicated to the success of your business</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
